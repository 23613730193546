
import { defineComponent, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useDelivery } from '@/composables/useDelivery'
import localforage from 'localforage'
export default defineComponent({
    setup() {
        const { fetchDelivery } = useDelivery()
        const route = useRoute()

        onMounted(async () => {
            await fetchDelivery(+route.params.id)
        })

        onUnmounted(() => {
            localforage.removeItem('routeDetailsId')
        })

        return {}
    },
})
